import { FC, useState, useEffect } from "react";
import Typography from "../UI/Typography";
import EmaiIcon from "../../assets/svg/email.svg";
import WarningIcon from "../../assets/svg/warning.svg";
import DoubleCheckIcon from "../../assets/svg/double-check-icon.svg";
import Button from "../UI/Button";
import { useAppSelector } from "../../redux/store/hooks";
import "./style.css";
import authService from "../../services/authService";

interface IProps {
  onClick: () => void;
  value: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ConfirmEmailFooter: FC<IProps> = ({
  onClick,
  value,
  style,
  disabled,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const { verified, email } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!verified) return;
    setConfirmed(verified?.email || false);
  }, []);

  const sendEmail = async () => {
    const response = await authService.sendEmail(email);
    if (!response?.success) {
      alert("Email not sent");
    } else {
      alert("Email sent");
    }
  };

  return (
    <footer style={style} className="footer-main-container">
      {!confirmed ? (
        <div className="footer-container2">
          <img src={WarningIcon} />
          <div className="footer-container1">
            <Typography primary>Please confirm your email</Typography>
            <span className="send-again__container">
              <img className="email-icon" src={EmaiIcon} />
              <Typography
                onClick={sendEmail}
                style={{ color: "#007AFF" }}
                primary
              >
                Send again
              </Typography>
            </span>
          </div>
        </div>
      ) : (
        <div className="email-confirmed-container">
          <img className="email-icon double-check-icon" src={DoubleCheckIcon} />
          <Typography primary>Email Confirmed</Typography>
        </div>
      )}
      <Button
        disabled={disabled}
        onClick={onClick}
        classname="confirm-services-btn"
        value={value}
      />
    </footer>
  );
};

export default ConfirmEmailFooter;
