const useWebComponent = (component: string | any) => {
  const executeFunction = (componentFunction: any, params: any = null) => {
    if (typeof component === "string") {
      const componentElement: any = document.querySelector(component);
      if (componentElement && componentElement[componentFunction]) {
        if (!params) return componentElement[componentFunction]();
        else return componentElement[componentFunction](params);
      }
    }
    if (!component?.current) return;
    if (component.current[componentFunction]) {
      if (!params) return component.current[componentFunction]();
      else return component.current[componentFunction](params);
    }
  };
  const addListener = (name: string, callBack: any) => {
    if (typeof component === "string") {
      document.querySelector(component)?.addEventListener(name, callBack);
      return;
    }
    if (!component?.current) return;
    component.current.addEventListener(name, callBack);
  };

  const removeListener = (name: string, callBack: any) => {
    if (typeof component === "string") {
      window.removeEventListener(name, callBack);
    }
    if (!component?.current) return;
    component.current.removeEventListener(name, callBack);
  };

  return { executeFunction, addListener, removeListener };
};

export default useWebComponent;
