const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH,
  REACT_APP_X_APPLICATION_ID,
} = window._env_;

// API domain
// const api = {
//   protocol: REACT_APP_API_PROTOCOL || "http",
//   host: REACT_APP_API_HOST || "localhost",
//   port: REACT_APP_API_PORT || "3000",
//   basePath: REACT_APP_API_BASEPATH || "/",
// };

const X_APPLICATION_ID =
  REACT_APP_X_APPLICATION_ID ||
  "247bec83-2465-451c-852f-c13cbffe598c";

const api = {
  protocol: REACT_APP_API_PROTOCOL || "http",
  host: REACT_APP_API_HOST || "localhost",
  port: REACT_APP_API_PORT || "3000",
  basePath: REACT_APP_API_BASEPATH || "/",
};

const API_URL = `${api.protocol}://${api.host}${`:${api.port}`}`;

export { api, API_URL, X_APPLICATION_ID };
