import { ConfiguratorEnums } from "./../../enums/configurator";
import { ConfiguratorEvent } from "./../../types/configuratorTypes";
import { useEffect } from "react";
import { useCompanyData } from "./companyData";

interface DashboardHandlers {
  [ConfiguratorEnums.companyData]: (service: any) => void;
}

export const useConfigurator = (updateTheServices: (services: string) => {}) => {
  const { loading: companyDataLoading, handleCompanyData } = useCompanyData(updateTheServices);

  useEffect(() => {
    const loading = companyDataLoading;
    document.body.style.cursor = loading ? "wait" : "default";
  }, [companyDataLoading]);

  const handlers: DashboardHandlers = {
    [ConfiguratorEnums.companyData]: handleCompanyData,
  };

  const handleConfiguratorServiceChange = (service: ConfiguratorEvent) => {
    const handler = handlers[service.type as keyof DashboardHandlers];
    handler(service);
  };

  return {
    handleConfiguratorServiceChange,
  };
};
