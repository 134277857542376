import * as jose from "jose";

interface Token {
  userId: string;
  profileId: string;
  tenantId: string;
  exp: number;
  role: string;
}

export const decodeToken = (token: string) => {
  const decoded: any = jose.decodeJwt(token);
  const data: Token = {
    userId: decoded.user.id,
    profileId: decoded.user.profileId,
    tenantId: decoded.tenancy.tenantId,
    exp: decoded.exp || 0,
    role: decoded.user.role,
  };
  return data;
};
