import { FC } from "react";
import Typography from "../../components/UI/Typography";
import PlirioIcon from "../../assets/svg/plirio-icon.svg";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import { Formik, FormikHelpers, Form } from "formik";
import { SetPasswordSchema } from "../../validation-schemas/register";
import { useParams } from "react-router-dom";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { UserConfirmationPayload } from "../../types/user";
import { setVerified } from "../../redux/actions/user/userSlice";
import { useAppDispatch } from "../../redux/store/hooks";
import "./style.css";

const SetPassword: FC = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    if (!token) return;
    let payload: UserConfirmationPayload = {
      token: token,
      password: values.password,
    };
    await authService.confirmUser(payload).then((res) => {
      if (!res) {
        alert("Error setting password");
        return;
      }
      // dispatch(setVerified({ email: true }));
      navigate("/login");
    });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <div className="set-password-container">
      <div className="header-container">
        <img className="header-icon" src={PlirioIcon} alt="plirio-icon" />
        <Typography secondary>Set your password</Typography>
      </div>
      <Typography primary>
        To complete your registration and access your services, insert a
        password that you will use to access your account.
      </Typography>
      <Formik
        validationSchema={SetPasswordSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isValid }) => (
          <Form className="set-password-container">
            {/* <Input
              label="email"
              type="email"
              placeholder="lorencpu2@gmail.com"
              name="email"
            /> */}
            <Input
              label="new password"
              type="password"
              placeholder="Insert Password"
              name="password"
            />
            <Button
              disabled={!isValid}
              value="Complete registration"
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SetPassword;
