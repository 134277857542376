import { FC } from "react";
import PlirioIconBig from "../../assets/svg/plirio-icon-big.svg";
import Button from "../../components/UI/Button";
import Typography from "../../components/UI/Typography";
import { useLocation } from "react-router-dom";
import EmailIcon from "../../assets/svg/email.svg";
import authService from "../../services/authService";
import "./style.css";

const ThankYou: FC = () => {
  const location = useLocation();
  const email = location.state?.email;

  const openEmailProvider = () => {
    window.open("https://mail.google.com/mail/u/0/#inbox");
  };

  const sendEmail = async () => {
    const response = await authService.sendEmail(email);
    if (!response?.success) {
      alert("Email not sent");
    } else {
      alert("Email sent");
    }
  };

  return (
    <div className="thank-you-container">
      <img
        className="thank-you-container-plirio-icon"
        src={PlirioIconBig}
        alt="plirio-icon-big"
      />
      <Typography classname="thank-you-header">
        Thank you for your time.
      </Typography>
      <Typography primary>
        To complete your registration and access your services, open your email
        and click on the link that you have received.
      </Typography>
      <Typography primary>
        If you didn’t receive the link, you can use the button below to send
        another one.
      </Typography>
      <div className="thank-you-btn-container">
        <Button
          noArrow
          outlined
          icon={EmailIcon}
          value="Send another Email"
          onClick={sendEmail}
        />
        <Button
          noArrow
          value="Open Email provider"
          onClick={openEmailProvider}
        />
      </div>
    </div>
  );
};

export default ThankYou;
