import SwaggerClient from "swagger-client";
import { api, API_URL, X_APPLICATION_ID } from "../constants/config";
import store from "../redux/store";
import { logout } from "../redux/actions/user/userSlice";

const constructRequestHeaders = (request) => {
  const token = store.getState().user.token;
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  // request.headers.ContentType = "multipart/form-data";
  request.headers["x-application-id"] = X_APPLICATION_ID;

  return request;
};

const buildRequest = (requestBody) => {
  return {
    requestBody: requestBody,
    serverVariables: api,
  };
};

const swagger = new SwaggerClient(`${API_URL}/api-doc`, {
  requestInterceptor: (request) => {
    return constructRequestHeaders(request);
  },
  responseInterceptor: (response) => {
    if (response.status === 401) {
      store.dispatch(logout());
      window.location.href = "/login";
    }
    return response;
  },
});

const swaggerProps = {
  swagger,
  buildRequest,
};

export default swaggerProps;
