import { useMutation } from "react-query";
import { ConfiguratorEvent } from "../../types/configuratorTypes";
import { useAppSelector } from "../../redux/store/hooks";
import authService from "../../services/authService";

export const useCompanyData = (updateTheServices: (services: any) => {}) => {
  const { userId, profileId } = useAppSelector((state) => state.user);

  const { mutate: companyDataMutation, isLoading: isSetting } = useMutation(
    authService.updateProfile
  );

  //i am replacing base64 string that comes from the configurator with the url of the image to save space
  const updateServices = (values: ConfiguratorEvent, newValues: any) => {
    // let panelToUpdate = values.services[values.serviceId].steps
    //   .find((step: any) => step.id === values.stepId)
    //   .panels.find((panel: any) => panel.identifier === values.type);

    // panelToUpdate.fields.forEach((field: any) => {
    //   if (field.key == "avatar") {
    //     field.value.value.base64 = newValues.avatar;
    //   }
    //   if (field.key == "logo") {
    //     field.value.value.base64 = newValues.logo;
    //   }
    // });

    updateTheServices({
      ...values.services,
    });
  };

  const handleCompanyData = async (values: ConfiguratorEvent) => {
    const payload = {
      userId: userId,
      profileId: profileId,
      services: JSON.stringify(values.services),
      businessAddress: values.data.fullAddress.value,
      businessName: values.data.companyName.value,
      //   firstname: values.data.personalName.value,
      //   avatar: await base64ToFile(values.data.avatar.value.base64, 'avatar.png'),
    };

    companyDataMutation(payload, {
      onSuccess: (data: any) => {
        if (!data) {
          alert("Could not update data");
        } else {
          updateServices(values, data);
        }
      },
      onError: () => {
        return alert("Could not update data");
      },
    });
  };

  return {
    handleCompanyData,
    loading: isSetting,
  };
};
