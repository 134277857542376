import { decodeToken } from "../utils/token";
import swaggerProps from "../utils/swagger-client";
import {
  GetProfilePayload,
  LoginPayload,
  UpdateProfilePayload,
  UserConfirmationPayload,
} from "../types/user";

const registerUser = async (payload: any): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.User.createUser(
      {},
      swaggerProps.buildRequest({
        role: "admin",
        identityId: payload.email,
        provider: "email",
        profile: {
          type: "admin",
          firstname: payload.name,
          surname: payload.surname,
          services: "",
        },
      })
    );
    const data = response.body.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

const loginUser = async (payload: LoginPayload): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.Auth.login(
      {},
      swaggerProps.buildRequest({
        email: payload.email,
        password: payload.password,
      })
    );
    const data = response.body.data;
    const { userId, profileId, role, tenantId } = decodeToken(data.token);
    return {
      userId,
      profileId,
      role,
      tenantId,
      token: data.token,
    };
  } catch (error) {
    console.log(error);
  }
};

const getProfileById = async (payload: GetProfilePayload): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.Profile.retrieveProfile(
      { profileId: payload.profileId, userId: payload.userId },
      swaggerProps.buildRequest()
    );
    const data = response.body.data;
    return {
      services: data.services,
      verified: data.userId.level === "verified",
    };
  } catch (error) {
    console.log(error);
  }
};

const updateProfile = async (payload: UpdateProfilePayload): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.Profile.updateProfile(
      { userId: payload.userId, profileId: payload.profileId },
      swaggerProps.buildRequest(payload)
    );
    const data = response.body.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

const confirmUser = async (payload: UserConfirmationPayload): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.Auth.confirmUser(
      { confirmationService: "email" },
      swaggerProps.buildRequest({
        token: payload.token,
        password: payload.password,
      })
    );
    const data = response.body.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

const sendEmail = async (payload: string): Promise<any> => {
  try {
    const client = await swaggerProps.swagger;
    const response = await client.apis.Auth.resendConfirmationEmail(
      { email: payload },
      swaggerProps.buildRequest()
    );
    const data = response.body.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

const authService = {
  registerUser,
  confirmUser,
  updateProfile,
  loginUser,
  getProfileById,
  sendEmail,
};

export default authService;
