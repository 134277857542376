import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../../types/user";

const initialState: User = {
  token: "",
  userId: "",
  profileId: "",
  tenantId: "",
  email: "",
  isLoggedIn: false,
  role: "",
  verified: {
    email: false,
    phone: false,
  },
  services: null,
};

export const authSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    setUser: (state, action) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.profileId = action.payload.profileId;
      state.tenantId = action.payload.tenantId;
      state.role = action.payload.role;
      state.email = action.payload.email;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userId = "";
      state.profileId = "";
      state.tenantId = "";
      state.role = "";
      state.token = "";
      state.services = null;
      state.email = "";
      state.verified = {
        email: false,
        phone: false,
      };
    },
    setVerified(state, payload) {
      state.verified = {
        ...state.verified,
        ...payload.payload,
      };
    },
    updateServices: (state, action) => {
      state.services = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { login, logout, updateServices, setVerified, setUser } =
  authSlice.actions;
export default authSlice.reducer;
