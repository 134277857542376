import Typography from "../../components/UI/Typography";
import PlirioIcon from "../../assets/svg/plirio-icon.svg";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import { useNavigate } from "react-router-dom";
import Br from "../../components/UI/Br";
import { Formik, FormikHelpers, Form } from "formik";
import authService from "../../services/authService";
import { LoginSchema } from "../../validation-schemas/register";
import { useAppDispatch } from "../../redux/store/hooks";
import {
  login,
  setVerified,
  updateServices,
  setUser,
  logout,
} from "../../redux/actions/user/userSlice";
import { LoginPayload } from "../../types/user";
import "./style.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: LoginPayload) => {
    let result: any;
    await authService.loginUser(values).then((res) => {
      if (!res) {
        alert("Error logging in");
        return;
      }
      result = res;
      dispatch(
        setUser({
          userId: res.userId,
          token: res.token,
          role: res.role,
          profileId: res.profileId,
          tenantId: res.tenantId,
        })
      );
    });
    if (!result) return;
    await authService
      .getProfileById({ userId: result.userId, profileId: result.profileId })
      .then((res) => {
        if (!res) {
          alert("Error logging in 2");
          dispatch(logout());
          return;
        }
        dispatch(updateServices(res.services));
        if (res.verified) {
          dispatch(setVerified({ email: true }));
        }
        dispatch(login());
        navigate("/services");
      });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <div className="login-container">
      <div className="login-content-container">
        <div className="header-container">
          <img className="header-icon" src={PlirioIcon} alt="plirio-icon" />
          <Typography secondary>Plirio services configuration</Typography>
        </div>
        <br />
        <Typography primary>
          Welcome to the Plirio services configuration. Please provide your
          email and you password to proceed.
        </Typography>
        <Br times={2} />
        <Formik
          validationSchema={LoginSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isValid }) => (
            <Form>
              <div className="inputs-container">
                <Input
                  name="email"
                  label="EMAIL"
                  placeholder="Insert your email"
                />
                <Input
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Insert your password"
                />
              </div>
              <Br />
              <Button
                style={{ maxWidth: "321px" }}
                disabled={!isValid}
                value="Login"
                type="submit"
              />
              <Br times={2} />
              <button onClick={() => navigate("/register")}>REGISTER</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
