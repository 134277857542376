import "./index.css";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Services from "./pages/Services";
import ThankYou from "./pages/ThankYou";
import SetPassword from "./pages/SetPassword";
import { useAppSelector } from "./redux/store/hooks";

function App() {
  const { isLoggedIn } = useAppSelector((state) => state.user);

  return (
    <div className="main-container">
      <BrowserRouter>
        <Routes>
          {!isLoggedIn && (
            <>
              {/* <Route index path="/services" element={<Register />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/set-password/:token" element={<SetPassword />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          {isLoggedIn && (
            <>
              <Route path="/services" element={<Services />} />
            </>
          )}
          <Route path="*" element={<Navigate to={isLoggedIn ? "/services" : "/login"} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
