import "./style.css";
import { FC } from "react";
import ButtonArrow from "../../../assets/svg/button-arrow.svg";

interface IProps {
  classname?: string;
  disabled?: boolean;
  primary?: boolean;
  value?: string;
  type?: "button" | "submit" | "reset";
  noArrow?: boolean;
  outlined?: boolean;
  style?: React.CSSProperties;
  icon?: any;
  onClick?: () => void;
}

const Button: FC<IProps> = ({
  value = "Click",
  type = "button",
  disabled = false,
  style,
  classname = "",
  noArrow = false,
  outlined = false,
  icon,
  onClick,
}) => {
  const className = `button ${classname} ${
    disabled ? "btn-disabled" : !outlined && "button-hover"
  } ${outlined ? "btn-outlined" : ""}`;
  return (
    <>
      <button
        style={style}
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={className}
      >
        <div className="btn-value_container">
          {icon && (
            <img className="btn-prop-icon" src={icon} alt="button-arrow" />
          )}
          <span>{value}</span>
          {!noArrow && <img src={ButtonArrow} alt="button-arrow" />}
        </div>
      </button>
    </>
  );
};

export default Button;
