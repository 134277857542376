import { FC } from "react";

interface IProps {
  times?: number;
}

const Br: FC<IProps> = ({ times = 1 }) => {
  return (
    <>
      {[...Array(times)].map((_, index) => (
        <br key={index} />
      ))}
    </>
  );
};

export default Br;
