import { useWindowWidth } from "@react-hook/window-size/throttled";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import ConfirmEmailFooter from "../../components/ConfirmEmailFooter";
import useWebComponent from "../../hooks/useWebComponent";
import { useAppSelector, useAppDispatch } from "../../redux/store/hooks";
import { logout, updateServices as changeServices } from "../../redux/actions/user/userSlice";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { useConfigurator } from "../../hooks/configurator";
import ConfiguratorServices from "../../constants/services.json";
import "./style.css";

const Services: FC = () => {
  const [page, setPage] = useState("services");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [services, setServices] = useState<any>();
  const [obligatorySteps, setObligatorySteps] = useState<boolean>(false);
  const [cartDisabled, setCartDisabled] = useState<boolean>(false);
  const configuratorRef = useRef<any>(null);
  const updatedRef = useRef<string>();
  const cartChangedRef = useRef<string>();
  const screenWidth = useWindowWidth();
  const { userId, profileId, services: savedServices } = useAppSelector((state) => state.user);
  const { addListener, removeListener, executeFunction } = useWebComponent(configuratorRef);
  const { handleConfiguratorServiceChange } = useConfigurator(updateServices);

  //navigating to the first page of the configurator when the user presses the back button,but it is
  //unstable and some times the page state is confused
  // window.addEventListener(
  //   "popstate",
  //   async (event) => {
  //     if (page === "console") {
  //       await setComponentPage("services");
  //       return;
  //     }
  //   },
  //   false
  // );

  const checkObligatorySteps = useCallback((services: any) => {
    if (!services) return;
    let hasObligatoryStepFields = true;
    for (const service of services) {
      for (const step of service.steps) {
        if (!step.optional) {
          if (step.panels[0].fields.length === 0) {
            hasObligatoryStepFields = false;
            break;
          }
        }
      }
    }
    setObligatorySteps(hasObligatoryStepFields);
  }, []);

  const getServices = async () => {
    const services = await executeFunction("getServices");
    console.log(services);
    return services;
  };

  const setComponentPage = async (page: string) => {
    await executeFunction("setPage", page);
  };

  const handlePageChange = useCallback((e: any) => {
    setPage(e.detail.page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const servicesChanged = useCallback((e: any) => {
    console.log("services changed", e.detail);
    let data = e.detail;
    handleConfiguratorServiceChange(data);
    // updateServices(data.services);
    setServices(data);
  }, []);

  const serviceToggled = useCallback((e: any) => {
    let data = e.detail;
    updateServices(data.services);
    setServices(data);
  }, []);

  const buyButtonPressed = useCallback((e: any) => {
    handlePurchase();
  }, []);

  useEffect(() => {
    // setComponentPage(page);
    // if (savedServices) {
    //   checkObligatorySteps(JSON.parse(savedServices));
    // }
    addListener("servicechanged", servicesChanged);
    addListener("serviceToggled", serviceToggled);
    addListener("buybuttonpressed", buyButtonPressed);
    addListener("pagechange", handlePageChange);

    return () => {
      removeListener("servicechanged", servicesChanged);
      removeListener("buybuttonpressed", buyButtonPressed);
      removeListener("pagechange", handlePageChange);
      removeListener("serviceToggled", serviceToggled);
    };
  }, []);

  async function updateServices(servicesToAdd: any) {
    if (updatedRef.current === JSON.stringify(servicesToAdd)) return;
    updatedRef.current = JSON.stringify(servicesToAdd);
    await authService
      .updateProfile({ services: JSON.stringify(servicesToAdd), userId, profileId })
      .then((res) => {
        if (!res) {
          alert("Could not update services");
          return;
        } else {
          dispatch(changeServices(JSON.stringify(servicesToAdd)));
          console.log("updated");
          alert("Services updated successfully");
        }
      });
  }

  const handlePurchase = async () => {
    const data = await getServices();
    const ids = data.cart.map((item: any) => item.id);
    for (let service in data.services) {
      if (ids.includes(data.services[service].id)) {
        data.services[service].payed = true;
      }
    }
    await executeFunction("emptyCart");
    setCartDisabled(true);
    updateServices(data.services);
  };

  const logoutUser = async () => {
    await setComponentPage("services");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="services-container">
      <div className="web-component_container">
        {/* <button onClick={getServices}>get services</button> */}
        <button onClick={logoutUser}>LOG OUT</button>
        <service-configurator
          ref={configuratorRef}
          savedServices={savedServices}
          services={JSON.stringify(ConfiguratorServices)}
        ></service-configurator>
      </div>

      <div>
        {page === "console" && screenWidth >= 880 && (
          <ConfirmEmailFooter
            disabled={services?.cart?.cost == null || cartDisabled}
            onClick={handlePurchase}
            value="Complete Purchase"
          />
        )}
        {page === "services" && (
          <ConfirmEmailFooter
            onClick={() => setComponentPage("console")}
            value="Configure your services"
          />
        )}
      </div>
    </div>
  );
};

export default Services;
