import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserReducer from "../actions/user/userSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "user",
  version: 1,
  storage,
};

const reducers = combineReducers({
  user: persistReducer({ ...persistConfig }, UserReducer),
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
