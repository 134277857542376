import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { defineCustomElements } from "service-configurator/loader";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";

// type CustomEvents<K extends string> = {
//   [key in K]: (event: CustomEvent) => void;
// };
// type CustomElement<T, K extends string> = Partial<
//   T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>
// >;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["service-configurator"]: any;
    }
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

defineCustomElements(window);
