import { FC } from "react";
import Typography from "../Typography";
import { ErrorMessage, useField } from "formik";
import "./style.css";

interface IProps {
  type?: "number" | "text" | "password" | "email" | "date";
  label?: string;
  icon?: any;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  value?: string;
  name: string;
}

const Input: FC<IProps> = ({
  type = "text",
  label,
  icon,
  placeholder,
  disabled,
  className,
  value,
  ...props
}) => {
  const [field, meta] = useField(props);
  const classname = `input-container ${className}`;
  return (
    <div className={classname}>
      <label className="input-label" htmlFor={label}>
        <Typography input>{label?.toUpperCase()}</Typography>
      </label>
      <div className="input-icons">
        <input
          className="input"
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          style={{ paddingLeft: icon ? "1rem" : "1rem" }}
          {...field}
          {...props}
        />
        {icon && (
          <span className="icon">
            <img src={icon} alt="icon" />
          </span>
        )}
      </div>
      <ErrorMessage component="div" name={field.name} className="input-error" />
    </div>
  );
};

export default Input;
