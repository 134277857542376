import * as Yup from "yup";

export const RegisterSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  surname: Yup.string()
    .max(20, "Surname must be 20 characters or less")
    .required("Surname is required"),
  name: Yup.string().max(20, "Name must be 20 characters or less").required("Name is required"),
});

export const LoginSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required"),
});

export const SetPasswordSchema = Yup.object({
  email: Yup.string().email("Email is invalid"),
  password: Yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // ),
});
